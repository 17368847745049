import Separator from "@/pages/separator";
import Image from "next/image";
import Link from "next/link";

import logo from "../../public/images/logo/logo.png";
import logoDark from "../../public/images/logo/logo-dark.png";
import Securxlogo from "../../public/images/logo/Securxlogo.png";

import FooterData from "../../data/footer.json";
import SingleFooter from "./FooterProps/SingleFooter";

const Footer = () => {
  return (
      <>
        <footer className="rainbow-footer footer-style-default footer-style-3 position-relative">
          <Separator top={true} />
          <div className="footer-top">
            <div className="container">
              <div className="row justify-content-center mb--30">
                <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                  <div className="rainbow-footer-widget text-center">
                    <div className="logo">
                      <Link href="/">
                        <Image
                            className="logo-light"
                            src={Securxlogo}
                            width={201}
                            height={35}
                            alt="Corporate Logo"
                        />
                        <Image
                            className="logo-dark"
                            src={logoDark}
                            width={201}
                            height={35}
                            alt="Corporate Logo"
                        />
                      </Link>
                    </div>
                    <p className="b1 text-center mt--20 mb--0">
                      See your Network, Cloud and Applications through a hacker's eyes.
                    </p>
                  </div>
                </div>
              </div>

              <div className="row justify-content-center mt--30">
                <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                  <div className="rainbow-footer-widget text-center">
                    <h4 className="title">Contact info</h4>
                    <p className="b1 mt--20 mb--0">Location: Wichita, Kansas</p>
                    <p className="b1 mt--10 mb--0">Email: info@securx.us</p>
                    <p className="b1 mt--10 mb--0">Phone: (316) 771-1904</p>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </footer>
      </>
  );
};

export default Footer;
